<template>
  <aside class="footer-light wf-section">
    <div class="container-2">
      <div class="footer-wrapper-two">
        <a class="footer-brand w-inline-block">
          <img
            v-if="logoData"
            :src="logoData.logo"
            loading="lazy"
            sizes="(max-width: 479px) 40vw, 90px"
            alt=""
            style="width: 80%"
          />
          <img
            v-else
            src="@/assets/images/world-appeal-white.png"
            srcset="
              @/assets/images/world-appeal-white.png 500w,
              @/assets/images/world-appeal-white.png 578w
            "
            loading="lazy"
            sizes="(max-width: 479px) 40vw, 90px"
            alt=""
            style="width: 80%"
          />
        </a>
      </div>
      <div class="footer-wrapper-two">
        <!-- <div class="footer-block-two">
          <div class="footer-title">Appeals</div>

          <a v-for="item in appealData" :key="item" :href="item.url" class="footerlinkblock w-inline-block">
            <div>{{ item.title }}</div>
          </a>
        </div>
        <div class="footer-block-two">
          <div class="footer-title">About us</div>

          <a v-for="item in aboutData" :key="item" :href="item.url" class="footerlinkblock w-inline-block">
            <div>{{ item.title }}</div>
          </a>
        </div> -->
        <div
          class="footer-block-two"
          v-for="data in footerData"
          :key="data.footer.id"
        >
          <div class="footer-title">{{ data.footer.title }}</div>

          <a
            v-for="item in data.links"
            :key="item.id"
            :href="getRoute(item)"
            :target="item.actionType == 'url' ? '_blank' : '_self'"
            class="footerlinkblock w-inline-block"
          >
            <div>{{ item.text }}</div>
          </a>
        </div>
        <div class="footer-block-two" v-if="contactObj">
          <div class="footer-title">{{ contactObj.footer.title }}</div>
          <template v-for="item in contactObj.links">
            <template v-if="item.type == 'whatsapp'">
              <a
                class="footerlinkblock w-inline-block"
                :href="formatWhatsAppLink(item.text)"
                target="_blank"
              >
                <div>
                  <img
                    src="@/assets/images/icons8-whatsapp.svg"
                    loading="lazy"
                    alt=""
                  />
                  {{ item.text }}
                </div>
              </a>
            </template>
            <template v-if="item.type == 'email'">
              <a
                class="footerlinkblock w-inline-block"
                :href="'mailto:' + item.text"
              >
                <div>
                  <img
                    src="@/assets/images/icons8-envelope-48.png"
                    loading="lazy"
                    alt=""
                  />
                  {{ item.text }}
                </div>
              </a>
            </template>
            <template v-if="item.type == 'location'">
              <a
                class="footerlinkblock w-inline-block"
                :href="item.link"
                target="_blank"
              >
                <div>
                  <img
                    src="@/assets/images/icons8-location-50.png"
                    loading="lazy"
                    alt=""
                  />
                  {{ item.text }}
                </div>
              </a>
            </template>
          </template>

          <div class="footer-social-block-two">
            <template v-for="item in contactObj.socials">
              <template v-if="item.type == 'twitter'">
                <a
                  :href="item.link"
                  target="_blank"
                  class="footer-social-link w-inline-block"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </template>
              <template v-if="item.type == 'instagram'">
                <a
                  :href="item.link"
                  target="_blank"
                  class="footer-social-link w-inline-block"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="currentColor" fill-rule="evenodd">
                      <path
                        d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"
                      ></path>
                      <path
                        d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"
                      ></path>
                    </g>
                  </svg>
                </a>
              </template>
              <template v-if="item.type == 'facebook'">
                <a
                  :href="item.link"
                  target="_blank"
                  class="footer-social-link w-inline-block"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 11 23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </template>
            </template>

            <a
              v-if="contactObj.whatsapp"
              :href="formatWhatsAppLink(contactObj.whatsapp.text)"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@worldappeal"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/worldappeal/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.tiktok.com/@worldappeal"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64c.298-.002.595.042.88.13V9.4a6.33 6.33 0 0 0-1-.08A6.34 6.34 0 0 0 3.52 19.5a6.34 6.34 0 0 0 10.7-4.58v-7a8.16 8.16 0 0 0 5.37 2.03v-3.27a4.85 4.85 0 0 1-1-.1z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="footer-block-two" v-else>
          <div class="footer-title">Contact</div>
          <a
            class="footerlinkblock w-inline-block"
            :href="formatWhatsAppLink('07864001000')"
            target="_blank"
          >
            <div>
              <img
                src="@/assets/images/icons8-whatsapp.svg"
                loading="lazy"
                alt=""
              />
              0786 400 1000
            </div>
          </a>
          <a
            class="footerlinkblock w-inline-block"
            href="mailto:info@worldappeal.org.uk"
          >
            <div>
              <img
                src="@/assets/images/icons8-envelope-48.png"
                loading="lazy"
                alt=""
              />
              info@worldappeal.org.uk
            </div>
          </a>
          <a class="footerlinkblock w-inline-block" href="#">
            <div>
              <img
                src="@/assets/images/icons8-location-50.png"
                loading="lazy"
                alt=""
              />
              126-128 Bury Park Road Luton, LU1 1HE
            </div>
          </a>
          <div class="footer-social-block-two">
            <a
              href="https://twitter.com/worldappeal"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/worldappealofficial/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="currentColor" fill-rule="evenodd">
                  <path
                    d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"
                  ></path>
                  <path
                    d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/worldappealofficial/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 11 23"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@worldappeal"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/worldappeal/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.tiktok.com/@worldappeal"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64c.298-.002.595.042.88.13V9.4a6.33 6.33 0 0 0-1-.08A6.34 6.34 0 0 0 3.52 19.5a6.34 6.34 0 0 0 10.7-4.58v-7a8.16 8.16 0 0 0 5.37 2.03v-3.27a4.85 4.85 0 0 1-1-.1z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div
        class="footer-bottom pt-3 flex-row flex-wrap gap-1"
        style="border-top: 1px solid gray"
      >
        <div class="footer-copyright m-0">
          © {{ year }} World Appeal. All rights reserved
        </div>
        <div class="d-flex flex-wrap gap-2">
          <a href="/privacy-policy" class="footerlinkblock w-inline-block mt-0">
            <div>Privacy Policy</div>
          </a>
          <a
            href="/terms-and-conditions"
            class="footerlinkblock w-inline-block mt-0"
          >
            <div>Terms & Conditions</div>
          </a>
        </div>
      </div>
    </div>
  </aside>
  <div
    v-if="false"
    class="back_to_top"
    :style="{ display: scrollPosition > 600 ? 'block' : 'none' }"
    @click="scrollTop"
  >
    <img
      width="30"
      height="30"
      src="@/assets/images/worldappeal/top.png"
      alt="to top"
      loading="lazy"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      scrollPosition: null,
      year: new Date().getFullYear(),
      appealData: [
        { title: "Education Fund", url: "#" },
        { title: "Food Fund", url: "#" },
        { title: "Water Fund", url: "#" },
        { title: "Muhammad's Kitchen", url: "#" },
        { title: "Aid Program", url: "#" },
      ],
      aboutData: [
        { title: "How we started", url: "#" },
        { title: "Become a Volunteer", url: "#" },
        { title: "Donate Now", url: "#" },
        { title: "Registered Charity No. 1194117", url: "#" },
      ],
      dataLoading: false,
      footerData: [],
      contactObj: null,
      loading2: false,
      logoData: null,
    };
  },
  props: {
    allPages: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    this.loadData();
    this.LoadLogo();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    formatWhatsAppLink(phoneNumber) {
      // Remove all non-numeric characters except '+' and keep only the first '+'
      const cleanNumber = phoneNumber
        .replace(/\s+/g, "") // Remove all whitespace
        .replace(/[()-]/g, "") // Remove parentheses and hyphens
        .replace(/[^\d+]/g, "") // Remove any other non-digit and non-plus characters
        .replace(/(\+.*)(\+)/, "$1"); // Keep only the first + if multiple exist

      // If number starts with '+', use it as is, otherwise add +44
      const formattedNumber = cleanNumber.startsWith("+")
        ? cleanNumber
        : `+44${
            cleanNumber.startsWith("0") ? cleanNumber.slice(1) : cleanNumber
          }`;

      return `https://wa.me/${formattedNumber.replace("+", "")}`; // WhatsApp API doesn't need + in the URL
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollTop() {
      window.scrollTo({ top: 10, behavior: "smooth" });
    },
    getRoute(item) {
      return item.actionType == "url"
        ? item.link
        : item.actionType == "page" && this.getPage(item.pageID) == "home"
        ? this.$route.name == "home"
          ? "#"
          : "/"
        : item.actionType == "page" &&
          item.pageID != this.getPageID(this.$route.params.title)
        ? `/page/${this.getPage(item.pageID)}`
        : "#";
      // return item.actionType == "url"
      //   ? item.link
      //   : item.actionType == "page" && item.pageID != this.$route.params.id
      //   ? `/page/${item.pageID}`
      //   : "#";
    },
    getPage(id) {
      let obj = this.allPages.find((el) => el.id == id);
      return obj ? obj.title.replace(/\s+/g, "-").toLowerCase() : "";
    },
    getPageID(title) {
      let obj = this.allPages.find(
        (el) => el.title.replace(/\s+/g, "-").toLowerCase() == title
      );
      return obj ? obj.id : "";
    },
    loadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Footer/LoadData",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          this.footerData = response.data.data.filter(
            (el) => el.footer.type != "contact"
          );
          let obj = response.data.data.find(
            (el) => el.footer.type == "contact"
          );
          if (obj) {
            this.contactObj = {
              footer: obj.footer,
              links: obj.links.filter((el) => el.actionType != "social"),
              socials: obj.links.filter((el) => el.actionType == "social"),
              whatsapp: obj.links.find((el) => el.type == "whatsapp"),
            };
          } else this.contactObj = null;
          // console.log(this.contactObj);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadLogo() {
      this.loading2 = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Logos",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.logoData = res.data.data.find((el) => el.type == "footer");
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.loading2 = false));
    },
  },
};
</script>
